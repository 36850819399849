.hive-admin-wrapper {
  width: 1000px;
  margin: 60px auto 40px auto;

  .title {
    margin-bottom: 25px;
    text-align: center;
  }

  .hive-admin-table {
    margin: 15px 0 25px 0;
    width: 100%;

    th, td {
      text-align: center;
    }
  }

  .pagination-wrapper {
    text-align: right;

    .page-selector {
      width: 60px;
    }
  }
}
