.account-tabs {

  #account-ip-addresses {

    select.ip-addresses-list {
      height: 200px;
      overflow-x: auto;
    }

    .confirm-ip-address {

      a, a:visited {
        text-decoration: underline;
        color: #000;
      }

      a:hover {
        color: #e5ac63;
      }

    }

  }

}
