.licensing-wrapper {

  padding: 48px;

  .title {
      font-size: 19pt;
      font-weight: bold;
      margin-bottom: 48px;
  }

  .content {
    float: left;
    text-align: center;
    width: 100%;
    margin-bottom: 48px;

    .menu {
      float: left;
      width: 300px;
      font-size: 19pt;
      font-weight: bold;
      line-height: 200%;
      text-align: left;
      cursor: pointer;
      text-transform: uppercase;

      .active {
        color: #69c09e;
      }
    }

    .ul {
      display: inline-block;
      margin-left: -300px;
      text-align: left;
      font-weight: bold;
      font-size: 15pt;
      width: 740px;
      line-height: 150%;

      .li {
        padding-left: 32px;
        background: url('/img2/check.png') no-repeat left center;
      }
    }

    .block {
      vertical-align: top;
      display: inline-block;
      width: 370px;
      line-height: 300%;
      font-size: 17pt;
      margin-top: 48px;
      margin-left: 10px;
      margin-right: 10px;

      .title {
        background-color: #000;
        color: #fff;
        padding: 0;
        margin: 0;
        font-size: 17pt;
      }

      .price {
        font-weight: bold;
      }

      .button {
        padding: 10px 30px;
        color: #000;
        text-transform: uppercase;
      }
    }

    .individual {
    }

    .business {
      display: none;
    }
  }

}
