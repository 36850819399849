.clear {
  clear: both;
}

.material {
  textarea {
    resize: none;
  }
  input,
  textarea {
    height: unset;
    margin: unset;
    box-shadow: unset;
    background: none;
    color: #222222;
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    // width: 320px;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #c6c6c6;
  }
  input:focus,
  textarea:focus {
    outline: none;
  }
  input:focus ~ label,
  textarea:focus ~ label,
  input:valid ~ label,
  textarea:valid ~ label {
    top: -14px;
    font-size: 12px;
    color: #2196f3;
  }
  input:focus ~ .bar:before,
  textarea:focus ~ .bar:before {
    width: 320px;
  }
  input[type="password"] {
    letter-spacing: 0.3em;
  }
  label {
    color: #c6c6c6;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 300ms ease all;
  }
  .bar {
    position: relative;
    display: block;
    width: 320px;
  }
  .bar:before {
    content: "";
    height: 2px;
    width: 0;
    bottom: 0px;
    position: absolute;
    background: #2196f3;
    transition: 300ms ease all;
    left: 0%;
  }
}

.tag {
  padding: 8px 12px;
  border-radius: 20px;
  background: #b2b7bf;
  color: #fff;
  display: inline-block;
  font-size: 14px;
}
.tag.active {
  background: #69c09e;
  color: #222;
}
