.terms-wrapper {
  padding: 45px 100px 45px 100px;

  .update-at {
    float: right;
    font-style: italic;
    font-weight: 400;
  }

  .highlight {
    color: #77c8a7;
  }
}
