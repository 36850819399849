.covid-header {
  background-color: #fff;
  padding: 30px 0px;
  h1 {
    text-transform: capitalize;
    font-size: 60px;
  }
  .row {
    padding-top: 26px;
    max-width: 70em !important;
  }
  .sub-title {
    flex-grow: 1;
    flex-basis: 0;
  }
  .virus {
    margin-top: -80px;
    text-align: right;
  }
}
.covid-section {
  padding: 30px 0px;
  .container {
    max-width: 70em !important;
    text-align: center;
    padding: 60px;
    margin: 0 auto;
  }
  iframe {
    min-height: 300px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
