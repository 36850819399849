.settings {
  .recipient-new {
    display: none;

    .to {
      float: left;
    }

    input {
      float: left;
      width: 220px;
      font-size: 15px;
      color: black;
      border: 0;
      background: #f9f9f9;
      box-shadow: none;
      margin: 0;
    }

    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
      color: #cacaca;
    }

    input:-moz-placeholder,
    textarea:-moz-placeholder {
      color: #cacaca;
    }

    button {
      background-color: #69c09e;
      font-size: 16px;
      color: #222;
      /* font-weight: bold; */
      /* text-align: center; */
      margin: 0;
      /* margin-top: 8px; */
      /* margin-right: 5px; */
      padding: 9px;
      border-radius: 0 8px 8px 0;
    }
  }
}
.chat-wrapper {
  .chat-header-wrapper {
    width: 100%;
    height: 57px;
    line-height: 37px;
    padding: 10px;
    background-color: #fff;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid #eceff2;

    .recipient {
      float: left;
      font-size: 16px;
      font-weight: 500;
      color: #222222;

      .recipient-name {
      }
    }

    .hive-selector-wrapper {
      width: 200px;
      float: left;
      height: 37px;
      background: url("/img2/hive.png") no-repeat 5px center;
      background-color: #e7ece9;
      padding-left: 32px;
      border-left: 1px solid #ddd;

      select {
        border-color: transparent;
        border: none;
        outline: none;
        font-size: 15pt;
        padding: 0;
        background: url("/img2/dropdown.png") no-repeat 150px center;
        background-color: #e7ece9;
        color: #404041;

        option {
          border: none;
          outline: none;
          font-size: 12pt;
          background-color: #e7ece9;
        }
      }
    }

    .search-button {
      float: right;

      span {
        padding-left: 26px;
        margin-right: 16px;
        background: url("/img2/search.png") no-repeat left center;
        font-size: 15pt;
        cursor: pointer;
      }

      input {
        position: absolute;
        margin-top: 10px;
        margin-left: -240px;
        width: 270px;
        font-size: 14px;
        color: black;
        background-color: #f9f9f9;
        border: 1px solid #dededd;
      }

      input::-webkit-input-placeholder,
      textarea::-webkit-input-placeholder {
        color: #cacaca;
      }

      input:-moz-placeholder,
      textarea:-moz-placeholder {
        color: #cacaca;
      }
    }
  }

  .chat-content-wrapper {
    padding: 16px;
    overflow-y: auto;
    background: #ffffff;
    border-radius: 0 0 8px 8px;
    margin-bottom: 16px;

    .message-wrapper {
      margin-bottom: 48px;

      .message-from-me {
        color: #e5ac63;
      }

      .message-to-me {
        color: #69c09e;
      }

      .sender-wrapper {
        float: left;
        width: 100%;
        text-align: left;

        .sender-avatar {
          float: left;
          display: inline-block;
          margin-top: 5px;
        }

        .sender-middle {
          float: left;
          margin-left: 10px;
          margin-top: 6px;
          width: calc(100% - 60px);

          .sender-name {
            font-size: 12px;
            font-weight: 300;
          }

          .to-hive {
            margin-top: -4px;
            font-size: 10pt;
            font-weight: bold;
            line-height: normal;
          }

          .message-content {
            margin-top: 5px;
            white-space: pre-wrap;
            color: #000;
          }

          .message-attachment {
            margin-top: 16px;
            font-size: 14px;
            line-height: normal;

            .preview-attachment {
              display: none;
            }

            img {
              max-width: 250px;
              max-height: 250px;
              border: 1px solid #dededd;
              padding: 5px;
              display: none;
            }

            a {
              color: #777;
            }

            a:hover {
              color: #69c09e;
            }
          }

          .original-msg {
            color: #555;
            font-size: 12px;
          }
        }

        .timestampt {
          float: right;
          text-align: right;
          color: #b2b7bf;
          font-size: 14px;
        }

        .message-menu {
          float: right;
          clear: right;
          text-align: right;

          .menu-action {
            cursor: pointer;
          }

          .menu-content {
            display: none;
            background-color: #dededd;
            font-size: 10pt;
            color: #000;
            text-align: left;
            padding: 5px 0px;

            span {
              cursor: pointer;
              display: block;
              padding: 5px 20px;
            }

            span:hover {
              color: #69c09e;
            }
          }
        }

        .secure-icon {
          background: url("/img/chat/chat-secure-icon.png") no-repeat center
            center;
          width: 11px;
          height: 14px;
          display: inline-block;
        }
      }
    }
  }

  .chat-new-message-wrapper {
    .chat-new-message {
      background-color: #f9f9f9;
      border-radius: 8px 8px 0px 0px;
      overflow: hidden;

      textarea {
        width: 100%;
        border-color: transparent;
        background-color: #f9f9f9;
        padding: 24px 16px;
        border: none;
        outline: none;
        resize: none;
        box-shadow: none;
      }

      .file-indicator {
        float: left;
        color: #636466;
        font-size: 11pt;
        padding-left: 40px;
        background: url("/img2/attach_idle.png") no-repeat left top;

        span {
          margin-left: 10px;
          cursor: pointer;
        }
      }

      .enter-to-send {
        float: right;
        margin-right: 16px;

        label {
          color: #636466;
        }
      }

      .show-images-inline {
        float: right;
        margin-right: 16px;

        label {
          color: #636466;
        }
      }

      .send-message-btn-wrapper {
        // float: right;
        // width: 75px;
        // height: 22px;
        // border-radius: 2px;
        // font-size: 12pt;
        // text-align: center;
        // background-color: #69c09e;
        // color: #fff;
        // cursor: pointer;
        // vertical-align: bottom;
      }
    }

    .chat-bottom-actions {
      display: flex;
      justify-content: space-between;
      background-color: #fff;
      padding: 12px 16px;
      border-radius: 0px 0px 8px 8px;
      overflow: hidden;

      .send-message-btn {
        padding: 12px 42px;
      }

      .left-side {
        align-items: center;
        color: #96999e;

        i {
          cursor: pointer;
        }
      }
    }

    .chat-footer {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;

      .r-side {
        display: flex;
      }
    }

    .chat-new-message-actions {
      float: right;
      width: 200px;
      font-size: 11pt;

      .action-attach-file {
        cursor: pointer;
      }

      .action-attach-file:hover {
        color: #69c09e;
      }

      .action-discard-message {
        cursor: pointer;
      }

      .action-discard-message:hover {
        color: #69c09e;
      }
    }

    .attach-file-wrapper,
    .secure-indicator {
      float: left;
      text-align: center;
      height: 37px;
      line-height: 37px;
      font-size: 10px;
      color: #b6b6b7;
      border-right: 1px solid #dededd;
    }

    .attach-file-wrapper {
      width: 105px;
      background: white url("/img/chat/chat-image-list-icon.png") no-repeat left
        center;
      margin-right: 10px;
      cursor: pointer;
      margin-left: 10px;
    }
  }

  .upload-usage {
    font-size: 13px;
    font-weight: 500;
    color: #3b3d41;
  }

  .chat-progress-modal {
    text-align: center;
  }

  .file-upload {
    position: absolute;
    top: -100px;
  }
}
