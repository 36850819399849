.news-wrapper {

  padding: 58px;

  .title {
    text-align: center;
    font-size: 40pt;
    font-weight: bold;
    line-height: normal;
    margin-bottom: 32px;
  }

  .content {
    width: 600px;
    margin: auto;
    font-size: 18pt;
    line-height: 24pt;

    .item {
      margin-top: 16px;
    }

    .desc {
      font-size: 30px;
      font-weight: bold;
    }

    .url {
    }
  }

}
