.hexagon {
  position: relative;
  width: 40px;
  height: 23.09px;
  margin: 11.55px 0;
  background-size: auto 46.1880px;
  background-position: center;
}

.hexTop,
.hexBottom {
  position: absolute;
  z-index: 1;
  width: 28.28px;
  height: 28.28px;
  overflow: hidden;
  -webkit-transform: scaleY(0.5774) rotate(-45deg);
  -ms-transform: scaleY(0.5774) rotate(-45deg);
  transform: scaleY(0.5774) rotate(-45deg);
  background: inherit;
  left: 5.86px;
}

/*counter transform the bg image on the caps*/
.hexTop:after,
.hexBottom:after {
  content: "";
  position: absolute;
  width: 40.0000px;
  height: 23.094010767585033px;
  -webkit-transform:  rotate(45deg) scaleY(1.7321) translateY(-11.5470px);
  -ms-transform:      rotate(45deg) scaleY(1.7321) translateY(-11.5470px);
  transform:          rotate(45deg) scaleY(1.7321) translateY(-11.5470px);
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  background: inherit;
}

.hexTop {
  top: -14.1421px;
}

.hexTop:after {
  background-position: center top;
}

.hexBottom {
  bottom: -14.1421px;
}

.hexBottom:after {
  background-position: center bottom;
}

.hexagon:after {
  content: "";
  position: absolute;
  top: 0.0000px;
  left: 0;
  width: 40.0000px;
  height: 23.0940px;
  z-index: 2;
  background: inherit;
}

.hexagonBig {
  position: relative;
  width: 150px;
  height: 86.60px;
  margin: 43.30px 0;
  background-size: auto 173.2051px;
  background-position: center;
}

.hexBigTop,
.hexBigBottom {
  position: absolute;
  z-index: 1;
  width: 106.07px;
  height: 106.07px;
  overflow: hidden;
  -webkit-transform: scaleY(0.5774) rotate(-45deg);
  -ms-transform: scaleY(0.5774) rotate(-45deg);
  transform: scaleY(0.5774) rotate(-45deg);
  background: inherit;
  left: 21.97px;
}

/*counter transform the bg image on the caps*/
.hexBigTop:after,
.hexBigBottom:after {
  content: "";
  position: absolute;
  width: 150.0000px;
  height: 86.60254037844388px;
  -webkit-transform:  rotate(45deg) scaleY(1.7321) translateY(-43.3013px);
  -ms-transform:      rotate(45deg) scaleY(1.7321) translateY(-43.3013px);
  transform:          rotate(45deg) scaleY(1.7321) translateY(-43.3013px);
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  background: inherit;
}

.hexBigTop {
  top: -53.0330px;
}

.hexBigTop:after {
  background-position: center top;
}

.hexBigBottom {
  bottom: -53.0330px;
}

.hexBigBottom:after {
  background-position: center bottom;
}

.hexagonBig:after {
  content: "";
  position: absolute;
  top: 0.0000px;
  left: 0;
  width: 150.0000px;
  height: 86.6025px;
  z-index: 2;
  background: inherit;
}
