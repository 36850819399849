.devices-wrapper {
  float: left;
  padding: 20px 100px 20px 100px;

  .title {
    margin-bottom: 50px;
  }

  .private-key-request-table {
    td, th {
      text-align: center;
      min-width: 100px;
      max-width: 200px;
    }
  }
}