.blog-wrapper {
  width: 100%;
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;

  .header {
    padding: 58px;
    text-align: center;

    .title {
      font-size: 40pt;
      font-weight: bold;
      line-height: normal;
      margin-bottom: 16px;
    }

    .block {
      vertical-align: top;
      display: inline-block;
      width: 295px;
      min-height: 50px;
      line-height: 50px;
      text-align: center;
      background-color: #69c09e;
      color: #fff;
      padding: 0;
      margin: 0;
      font-size: 18pt;
    }

    .action {
      cursor: pointer;
    }

    .sort {
      select {
        font-size: 18pt;
        line-height: normal;
        height: auto;
        border: none;
        margin: 0;
        padding: 0;
        outline: none;
        background: url("/img2/dropdown_white.png") no-repeat 20px 9px;
        background-color: #69c09e;
        text-align: center;
        text-align-last: center;
        color: #fff;
        cursor: pointer;

        option {
          background-color: #69c09e;
          text-align: center;
        }
      }
    }

    .filter {
      background: url("/img2/dropdown_white.png") no-repeat 25px 18px;
      background-color: #69c09e;

      .topics {
        display: none;
        padding: 8px;
        background-color: #fff;
        border: 1px solid #dededd;
        color: #000;
        text-align: left;
        line-height: normal;
        height: 150px;
        overflow-x: auto;

        .topic {
          padding: 5px 10px 8px 10px;
          border-bottom: 1px solid #dededd;

          input,
          label {
            font-size: 18px;
            line-height: normal;
          }

          label {
            padding-left: 32px;
          }

          label:hover {
            color: #69c09e;
          }
        }
      }
    }

    .search {
      background: url("/img2/search_white.png") no-repeat 20px 14px;
      background-color: #69c09e;

      .query {
        display: none;
        padding: 8px;
        background-color: #fff;
        border: 1px solid #dededd;
        color: #000;

        input {
          font-size: 18px;
          line-height: normal;
          border: 0;
          outline: 0;
          box-shadow: none;
          padding: 5px 10px 8px 10px;
          margin: 0;
        }
      }
    }
  }

  .content {
    line-height: normal;

    .found {
      font-size: 18pt;
      line-height: 50px;
      vertical-align: middle;
      margin-bottom: 32px;
    }

    .title {
      font-size: 30px;
      font-weight: bold;
    }

    .author {
      font-size: 20px;
      font-style: italic;
    }

    .types {
      float: right;
      font-size: 20px;
      font-style: italic;
      color: #aaa;
    }

    .abstract {
      font-size: 16px;
      margin-top: 8px;
      text-align: justify;
    }

    a:hover {
      .title {
        color: #69c09e;
      }
    }
  }
}
