.services-header {
  background-color: #fff;
  padding: 30px 0px;
  h1 {
    text-transform: capitalize;
    font-size: 60px;
  }
  .row {
    padding-top: 26px;
    max-width: 70em !important;
  }
  .sub-title {
    flex-grow: 1;
    flex-basis: 0;
  }
  .mini-card {
    background-color: #162b32;
    color: #fff;
    margin-right: 8px;
    border-radius: 8px;
    padding: 16px;
    width: 160px;
    height: 188px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-weight: normal;
    .title {
      font-size: 16px;
    }
    span {
      font-size: 12px;
    }
  }
}

.services-section {
  padding: 30px 0px;
  h2 {
    margin-top: 40px;
  }
  .container {
    max-width: 70em !important;
    text-align: center;
    padding: 60px;
    margin: 0 auto;
  }
  iframe {
    min-height: 300px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
