.staffs-wrapper {
  background: #77c8a7;
  float: left;
  width: 100%;
  color: white;
  padding: 35px 110px 135px 110px;

  .staffs-description {
    font-weight: 400;
    font-size: 20px;
  }

  .positions-wrapper {
    text-align: center;
    padding: 40px 0 0 0;

    .title {
      font-weight: bold;
      font-size: 22px;
      margin-bottom: 40px;
    }

    .staff {
      width: 240px;
      margin: auto;
      font-size: 15px;
      display: inline-block;

      .staff-name {
        font-weight: bolder;
      }

      .staff-img {
        width: 150px;
        height: 215px;
        margin: auto;
        margin-bottom: 25px;
        background-size: contain !important;

        &.staff-img-placeholder {
          background: url('/img/staffs/staff-placeholder.png') no-repeat center center;
        }

        &.staff-img-david {
          background: url('/img/staffs/staff-david.png') no-repeat center center;
        }

        &.staff-img-sem {
          background: url('/img/staffs/staff-sem.png') no-repeat center center;
        }

        &.staff-img-andre {
          background: url('/img/staffs/staff-andre.png') no-repeat center center;
        }

        &.staff-img-maron {
          background: url('/img/staffs/staff-maron.png') no-repeat center center;
        }
      }
    }
  }
}
