.video-container {
  display: inherit;
  margin: auto;
  width: auto;
  padding: 20px;
  border: 3px solid #222222;
  border-radius: 10px;
}

.continue-button {
  display: block;
  margin: 20px auto;
  width: 200px;
  padding: 13px 20px;
  text-align: center;
  background: #222222;
  color: #ffffff;
}

.absolute-link,
.absolute-link:visited,
.absolute-link:hover {
  position: absolute;
  right: 15px;
  top: 180px;
}

.continue-button:visited {
  color: #ffffff;
}

.continue-button:hover {
  color: #ffffff;
  background: #444444;
}

.document-container {
  display: none;
  padding-top: 50px;
  text-align: center;
}

.question-element {
  margin: auto;
  width: 80%;
  padding: 25px;
  box-shadow: 0 0 5px #444444;
}

.question-element-header {
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
}

.question-element-pagenum {
  float: right;
  width: 50px;
  font-weight: bold;
  text-align: right;
  color: #007095;
}

.question-element-answer {
  text-align: left;
}

.question-element-choose {
  margin-top: 30px;
  margin-bottom: 0;
}

.hide {
  display: none;
}

.show {
  display: block;
}