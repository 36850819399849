.partner-container {
  background-color: #fff;
  padding-bottom: 16px;
  // min-height: 100vh;
}

.partners-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 40px 0;
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    color: #000000;
    line-height: initial;
    margin-bottom: 14px;
  }
  .desc {
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: #000000;
    margin-bottom: 16px;
  }
}

.partner-list {
  background: #dcf3e8;
  border-radius: 54px 54px 0 0;
  border-radius: 54px;
  max-width: 915px;
  margin: 0 auto;
  padding: 0 54px;
  margin-bottom: 16px;
  .partner-item {
    display: flex;
    align-items: center;
    padding: 54px 0;
    border-bottom: 1px solid #162b32;

    .partner-logo {
      padding: 44px;
      background: #69c09e;
      border-radius: 19px;
    }

    .p-info {
      flex-grow: 1;
      padding: 20px;
    }

    .p-title {
      font-weight: 600;
      font-size: 18px;
    }
  }
  .partner-item:last-child {
    border: none;
  }
}

.partners-wrapper {
  padding: 100px;

  .title {
    font-weight: bold;
    margin-bottom: 50px;
    font-size: 20px;
  }

  p {
    line-height: 5px;

    &.bold {
      font-weight: bold;
      font-size: 20px;
    }
  }
  .partner-logos-wrapper {
    margin-bottom: 40px;
    margin-top: 40px;

    .partner-logo {
      width: 290px;
      height: 115px;
      background: #f4f4f4;
      float: left;
      margin-right: 15px;
      margin-bottom: 15px;

      div {
        height: 115px;
        margin: auto;
        width: 100%;
      }

      .small {
        width: 130px;
      }

      .venus-logo {
        background: url("/img/partners/venus-logo.png") no-repeat center center;
      }

      .vor-logo {
        background: url("/img/partners/vor-logo.png") no-repeat center center;
      }

      .security-labs-logo {
        background: url("/img/partners/security-labs-logo.png") no-repeat center
          center;
      }

      .cfib-logo {
        background: url("/img/partners/cfib-logo.png") no-repeat center center;
      }

      .ottawa-logo {
        background: url("/img/partners/ottawa-logo.png") no-repeat center center;
      }

      .tim-logo {
        background: url("/img/partners/tim-logo.png") no-repeat center center;
      }

      .itac-logo {
        background: url("/img/partners/itac-logo.png") no-repeat center center;
      }

      .himss-logo {
        background: url("/img/partners/himss-logo.png") no-repeat center center;
      }

      .iapp-logo {
        background: url("/img/partners/iapp-logo.png") no-repeat center center;
      }
    }
  }
}
