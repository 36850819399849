.conversation-wrapper {
  width: 400px;
  height: 100%;

  .search-contact-wrapper {
    height: 57px;
    line-height: 57px;
    text-align: center;
    color: #222;
    background-color: #f9f9f9;
    // padding: 10px;

    input {
      width: 100%;
      font-size: 16px;
      color: #222;
      border: 0;
      background: url("/img2/search-input.png") no-repeat right center;
      background-color: #f9f9f9;
      box-shadow: none;
      padding: 24px 16px;
    }

    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
      color: #cacaca;
    }

    input:-moz-placeholder,
    textarea:-moz-placeholder {
      color: #cacaca;
    }
  }

  .conversations-list-wrapper {
    height: 100%;

    .empty-state {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
    }
  }

  .list-item {
    // height: 72px;
    clear: both;
    cursor: pointer;

    .conversation-content {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 12px;
    }

    .conversation-picture {
    }

    .conversation-unread {
      position: absolute;
      font-size: 8pt;
      font-weight: bold;
      border-radius: 50%;
      background: #3477c7;
      color: #fff;
      width: 16px;
      height: 16px;
      margin-left: 28px;
      margin-top: 8px;
      text-align: center;
      line-height: 12pt;
      z-index: 9999;
    }

    .conversation-data {
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
      padding: 6px 12px;
      margin: 0;
      color: #999;

      .conversation-data-left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .conversation-username {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 8px;
          text-transform: capitalize;
        }

        .conversation-date {
          font-size: 13px;
          color: #aaa;
        }

        .conversation-draft {
          font-style: italic;
        }
      }

      .conversation-delete {
        float: right;
        margin-top: 8px;
        font-size: 14pt;
        font-weight: bold;
      }
    }
  }
}

.selected {
  .conversation-data {
    color: #fff !important;
  }
  .conversation-date {
    color: #fff !important;
  }
}

.unread {
  color: #000 !important;
  font-weight: bold;
  .conversation-date {
    color: #69c09e !important;
  }
  .conversation-delete {
    color: #999 !important;
  }
}
