.help-wrapper {
  padding: 110px 90px 110px 90px;

  .tabs-selector-wrapper {
    float: left;
    width: 30%;
  }

  .tabs-content {
    float: right;
    width: 70%;

    .faq-wrapper {
      height: 600px;
      overflow: scroll;
    }

    .video-wrapper {
      margin: auto;
      text-align: center;
    }
  }

  li {
    width: 290px;
    height: 70px;

    .play-icon {
      background: url("/img/help/play-icon.png") no-repeat center 65%;
      height: 60px;
      width: 10px;
      float: right;
      display: none;
    }

    &.active {
      a {
        color: black !important;
      }

      .play-icon {
        display: inline-block;
      }
    }

    hr {
      margin: 0;
    }

    a {
      display: inline-block !important;
      padding: 0 0 10px 0 !important;
      background-color: transparent !important;
      color: black !important;
    }
  }
}
