.tech-section {
  .row {
    padding: 120px 0;
    .sub-title {
      font-size: 50px;
      line-height: initial;
      margin-bottom: 16px;
    }
    .f-right {
      float: right;
    }
  }
}
.green-dot {
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background: #13c37b;
  margin: 0 auto;
}
.tech-bottom {
  background-color: #fff;
  .container {
    text-align: center;
    padding: 180px 0;
    p {
      font-weight: 600;
      font-size: 50px;
    }
  }
  .btn {
    width: fit-content;
    margin: 0 auto;
  }
  .green-dot {
    position: absolute;
    margin-top: -18px;
    left: calc(50% - 18px);
  }
}
