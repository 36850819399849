.company-wrapper {
  width: 100%;

  .company-brief-info {
    width: 60%;
    float: left;
    padding: 20px 100px 20px 100px;

    p {
      margin: 20px 0 20px 0;
    }

    h3 {
      margin: 40px 0 40px 0;
    }
  }

  .blog-wrapper {
    width: 40%;
    float: right;

    .blog-bar {
      height: 64px;
      background: #77c8a7;
      font-size: 23px;
      font-weight: 400;
      line-height: 64px;
      padding-left: 35px;
      color: white;
    }

    .pagesCont {
      color: #5e5e5e;

      .mini-post-wrapper {
        padding: 25px 20px 15px 20px;
        background: #f4f4f4;
        margin-bottom: 15px;

        .title {
          font-size: 20px;
          font-weight: bolder;
        }

        .author {
          margin: 15px 0 15px 0;
          font-style: italic;
          font-size: 15px;
        }

        .blog-url {
          text-align: right;
          text-decoration: underline;
        }
      }
    }

    .pagination-cont {
      float: right;
      margin-right: 25px;

      li {
        &:first-child, &:last-child {
          display: none;
        }

        a {
          background: url('/img/company/paginator-inactive.png') no-repeat center center;
          height: 35px;
          width: 35px;
          line-height: 35px;
          border-color: transparent;
          padding: 0;
          color: white;
        }

        &.active a {
          background: url('/img/company/paginator-active.png') no-repeat center center;

        }
      }
    }
  }
}
