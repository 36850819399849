.hives-wrapper {

  button {
    background-color: #69c09e;
    font-weight: bold;
  }

  #search-hive-name {
    border-bottom: 0px;
  }

  #search-hive-name {
    font-size: 15pt;
    color: black;
    border: 0;
    background: url('/img2/search-input.png') no-repeat right center;
    background-color: #e7ece9;
    box-shadow: none;
  }

  #search-hive-name::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: #cacaca;
  }

  #search-hive-name:-moz-placeholder, textarea:-moz-placeholder {
    color: #cacaca;
  }

  .hives-list {
    text-align: left;
    margin-left: auto;
    margin-right: auto;
  }
}

.hives-new-modal-wrapper {
  text-align: left;

  .row {
    margin-bottom: 10px;
  }

  .columns {
    vertical-align: middle;
  }

  .title {
    margin-bottom: 32px;
    font-size: 16pt;
    font-weight: bold;
  }

  label {
    font-weight: bold;
  }

  input {
    margin-bottom: 0px;
  }

  button {
    margin-top: 16px;
    margin-bottom: 0px;
    background-color: #69c09e;
    text-transform: uppercase;
    font-weight: bold;
  }

  em {
    font-size: 12px;
  }

}

.hive-wrapper {
  display: inline-block;
  background: url("/img/hives/icon-xahive.png") no-repeat 5px bottom;
  background-size: 27px 27px;
  background-color: #76c5a6;
  width: 200px;
  height: 110px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 10px;
  text-align: left;
  vertical-align: top;

  .title {
    height: 30px;
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
  }

  .features {
    height: 45px;
    font-size: 12px;
    font-style: italic;
    line-height: 14px;
    text-align: right;
    color: #fff;
  }

  .actions {
    height: 20px;
    text-align: right;
    font-size: 12px;
    vertical-align: bottom;
  }
}

.hive-settings-wrapper {

  .title {
    font-size: 24pt;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 50px;
    line-height: normal;
  }

  a:hover {
    color: #69c09e;
  }

  a:active {
    color: #69c09e;
  }

}

.hives-ip-addresses {

  margin-top: 20px;
  margin-bottom: 20px;

  button {
    padding: 10px;
    font-size: 14px;
    margin: 0;
  }

  em {
    color: #555;
  }

  select.ip-addresses-list {
    height: 200px;
    overflow-x: auto;
  }

  .confirm-ip-address {

    font-size: 12px;

    a, a:visited {
      text-decoration: underline;
      color: #000;
    }

    a:hover {
      color: #e5ac63;
    }

  }

}
