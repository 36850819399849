.statistic-table {

}

.first_block {
  width: 30%;
  float: left;
}

.first_block.wide,
.second_block.wide{
  width: 60%;
  padding-top: 15px;
  float: none;
  margin: auto;
}

.first_block.wide table,
.second_block.wide table {
  margin: auto;
}

.second_block {
  width: 70%;
  padding: 15px;
  float: right;
}

.table__title {
  font-weight: bold;
}

.table__title_align-center {
  //display: inline-block;
  text-align: center;
}

.link-to-copy {
  display: inline-block;
  overflow: hidden;
  width: 400px;
}

.second_block button {
  margin: 0;
}