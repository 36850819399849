.login {
  font-family: "Poppins", sans-serif;
  font-size: 11pt;

  .space {
    margin-top: 16px;
  }

  .password {
  }

  .forgot-password {
    margin-bottom: 16px;
  }

  .new-user {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .actions {
    margin-top: 16px;
    text-align: center;

    input[type="button"] {
      padding: 10px 20px;
      text-transform: capitalize;
    }
  }

  .toc {
    color: #555;
    margin-top: 24px;
    margin-bottom: 32px;
    font-size: 10pt;
    text-align: center;

    a {
      font-size: 10pt;
    }
  }

  input {
    margin: 0;
  }

  a,
  a:visited {
    color: #69c09e;
    font-weight: bold;
    font-size: 11pt;
  }

  h4 {
    text-align: center;
  }
}
