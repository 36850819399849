.home {
  background-color: #fff;
  .container {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 80px 60px;
    display: flex;
    flex-direction: row;

    .lead {
      flex-grow: 1;
      flex-basis: 0;
      margin: auto;
      margin-right: 15px;
      h1 {
        text-transform: capitalize;
        margin-bottom: 20px;
      }
      .signup-new {
        display: block;
        input {
          float: left;
          width: 220px;
          font-size: 15px;
          color: black;
          border: 0;
          background: #cdcdcd;
          box-shadow: none;
          margin: 0;
          height: 2.6rem;
        }
        button {
          background-color: #162b32;
          font-size: 16px;
          color: #fff;
          margin: 0;
          padding: 12px;
          border-radius: 0 8px 8px 0;
        }
      }
    }

    .lead-right {
      flex-grow: 1;
      flex-basis: 0;
      background: url("/img3/home-bg.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      margin-left: 15px;
    }
  }
}

.what-we-do {
  padding: 60px 0;

  &:nth-child(1) {
  }

  .desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-left: 3px solid #fff;
    margin: 40px 0;
    padding: 60px;
  }
}

.home-partners {
  justify-content: center;
  flex-direction: column !important;
  text-align: center;
  padding: 60px;

  p {
    font-weight: 500;
    font-size: 24px;
  }

  ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;

    display: flex;
    flex-direction: row;
  }
  li {
    text-align: center;
    flex-grow: 1;
    background: #eaeaea;
    border-radius: 20px;
    padding: 16px;
    margin: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.home-wrapper {
  font-family: "Poppins", sans-serif;
}

.home-wrapper .banner-wrapper {
  height: 100%;
  width: 100%;
}

.home-wrapper .banner-wrapper .banner {
  position: relative;

  .header-wrapper {
    position: absolute;
    top: 15%;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #222222;
    padding-left: 250px;
    padding-right: 250px;

    .header {
      font-size: 50pt;
      font-weight: bold;
      line-height: normal;
    }

    .sub-header {
      font-size: 36px;
    }
  }
}

.home-wrapper .banner-wrapper .banner .register-form-wrapper {
  width: 500px;
  height: 60px;
  margin: auto;
  position: absolute;
  display: none;
  left: calc(50% - 250px);
  top: 20%;
}

.home-wrapper .banner-wrapper .banner .register-form-wrapper input {
  width: 115px;
  height: 35px;
  line-height: 35px;
  display: inline-block;
  margin-right: -4px;
  box-shadow: none;
  margin-bottom: 5px;
}

.home-wrapper .banner-wrapper .banner .register-form-wrapper .submit-btn {
  width: 100px;
  height: 35px;
  line-height: 35px;
  display: inline-block;
  background: #77c8a7;
  color: white;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
}

.home-wrapper .banner-wrapper .banner .register-form-wrapper .terms-of-service {
  text-align: center;
  color: black;
}

.home-wrapper
  .banner-wrapper
  .banner
  .register-form-wrapper
  .terms-of-service
  span {
  color: black;
}

.home-wrapper
  .banner-wrapper
  .banner
  .register-form-wrapper
  input.register-email {
  width: 165px;
}

.home-wrapper .content1 {
  text-align: center;
  font-size: 18pt;

  .sub-title {
    margin-top: 53px;
    margin-bottom: 20px;
    font-size: 30pt;
    font-weight: bold;
  }

  .block {
    vertical-align: top;
    display: inline-block;
    width: 258px;
    margin-left: 30px;
    margin-right: 30px;

    .title {
      background-color: #69c09e;
      width: 258px;
      line-height: 68px;
      font-weight: bold;
      font-size: 18pt;
      color: #fff;
    }

    .text {
      margin-top: 16px;
      width: 258px;
      font-weight: normal;
      font-size: 13pt;
      text-align: center;
      color: #000;
    }
  }

  .privacy {
    background: url("/img2/key.png") no-repeat 40px 20px;
  }

  .security {
    background: url("/img2/lock.png") no-repeat 40px center;
    padding-left: 16px;
  }

  .encryption {
    background: url("/img2/key.png") no-repeat 24px 20px;
    padding-left: 24px;
  }
}

.home-wrapper .content2 {
  margin-top: 53px;
  padding-top: 48px;
  padding-bottom: 48px;
  text-align: center;
  font-size: 18pt;
  background-color: #69c09e;

  .sub-title {
    margin-bottom: 20px;
    font-size: 25pt;
    font-weight: bold;
  }

  .block {
    vertical-align: top;
    display: inline-block;
    width: 258px;
    margin-left: 30px;
    margin-right: 30px;

    .text {
      margin-top: 16px;
      width: 258px;
      font-weight: normal;
      font-size: 19pt;
      line-height: normal;
      text-align: center;
      color: #fff;
    }
  }
}

.home-wrapper .content3 {
  margin-top: 53px;
  padding-top: 48px;
  padding-bottom: 48px;
  text-align: center;
  font-size: 14pt;

  .sub-title {
    margin-bottom: 20px;
    font-size: 25pt;
    font-weight: bold;
  }

  .block {
    vertical-align: top;
    display: inline-block;
    width: 370px;

    .title {
      background-color: #69c09e;
      width: 370px;
      line-height: 68px;
      font-weight: bold;
      font-size: 18pt;
      color: #fff;
    }

    .text {
      background: url("/img2/check.png") no-repeat left center;
      padding-left: 24px;
      margin-top: 16px;
      font-weight: normal;
      font-size: 14pt;
      line-height: normal;
      text-align: left;
    }
  }

  .citation {
    margin-top: 80px;
    text-align: left;
    display: inline-block;
    width: 1000px;
    font-size: 12pt;
    background: url("/img2/SBDC.jpg") no-repeat left center;
    padding-left: 90px;

    .text {
      font-style: italic;
      text-align: justify;
    }

    .sep {
      margin-top: 16px;
      margin-bottom: 16px;
      height: 1px;
      width: 30px;
      background-color: #aaa;
    }

    .signature {
      font-weight: bold;
    }
  }
}

.home-wrapper .content4 {
  margin-top: 53px;
  padding-top: 48px;
  padding-bottom: 48px;
  text-align: center;
  font-size: 14pt;

  .sub-title {
    margin-bottom: 20px;
    font-size: 25pt;
    font-weight: bold;
  }
}
