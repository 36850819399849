.settings-wrapper {
  min-height: 100%;
  background-color: #fff;

  h3 {
    font-weight: 500;
    font-size: 22px;
    color: #222222;
  }
  h5 {
    font-size: 16px;
    font-weight: normal;
  }
  h3,
  h5 {
    text-transform: capitalize;
  }

  .menu {
    height: 200px;
    background-color: #f3f3f3;
    font-size: 50px;
    color: #222;

    .title {
      width: 100%;
      height: 100%;
      max-width: 1280px;
      margin: 0 auto;
      color: #222222;
      font-weight: 600;
      font-size: 50px;
      display: flex;
      align-items: center;
    }
  }

  .container {
    width: 100%;
    background-color: #fff;
    padding-bottom: 72px;
    .content {
      max-width: 1280px;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      padding: 0;
    }
  }

  .account-head {
    display: flex;
    .uname-field {
      flex-grow: 1;
      margin-left: 24px;
      .data-label {
        width: 100%;
        color: #b2b7bf;
        font-weight: normal;
        font-size: 14px;
      }
    }
  }

  .avatar-wrapper {
    // width: 150px;
    display: inline-block;

    .avatar-upload-wrapper {
      text-align: left;
      margin-top: 6px;
      margin-left: 8px;

      .file-upload {
        position: absolute;
        top: -10000px;
        left: -10000px;
      }
    }
  }

  .settings-command-buttons {
    padding-top: 20px;
    text-align: center;
    display: flex;
    // display: none;

    a {
      margin-right: 15px;
      margin-left: 15px;
    }
  }

  .settings-data-wrapper {
    background: white;

    .settings-data-item-wrapper {
      // border-bottom: 1px solid #dededd;
      padding: 15px;
      height: 50px;
      position: relative;

      &.editable {
        display: inline;

        // input {
        //   height: 22px !important;
        //   padding: 0px 8px 0px 8px;
        //   border: transparent;
        // }
      }

      .data-label {
        width: 100%;
        color: #b2b7bf;
        font-weight: normal;
        font-size: 14px;
      }

      .data-content {
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .data-label,
      .data-content {
        //float: left;
      }

      .data-edit-btn {
        position: absolute;
        display: none;
        right: 0px;
        top: 10px;

        a {
          width: 60px;
          height: 30px;
          padding: 0;
          line-height: 30px;
          right: 30px;
        }
      }
    }
  }

  .btable {
    background: #162b32;
    overflow: hidden;
    border-radius: 8px;
    padding: 2px;
    margin-bottom: 26px;
    box-shadow: 0px 10px 10px 0 rgba(0, 0, 0, 0.28);

    .state.YES:before {
      content: "";
      display: inline-block;
      width: 12px;
      height: 12px;
      -moz-border-radius: 6px;
      -webkit-border-radius: 6px;
      border-radius: 6px;
      background-color: #69c09e;
      margin-right: 10px;
    }
  }

  table,
  table tr,
  th,
  td {
    background: #162b32;
    color: #ffffff;
    font-weight: normal;
    font-size: 13px;
    line-height: 21px;
    padding: 8px 14px;
  }
  th {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    overflow: hidden;
    padding: 14px 14px;
  }
  tr:not(:first-child) {
    border-top: 1px solid #69c09e;
  }

  .version {
    clear: both;
    float: right;
    font-size: 12px;
    color: #777;
    margin-right: 30px;
  }

  .account-wrapper {
    // float: left;
    // margin: 30px;
    padding-top: 56px;
  }

  .keys-wrapper {
    margin-top: -130px;
    max-width: 570px;

    .restore-keys-upload {
      position: absolute;
      top: -10000px;
      left: -10000px;
    }
  }

  .actions .btn {
    display: inline-block;
  }

  .button {
    margin-bottom: 1em;
  }

  .hidden {
    display: none;
  }

  .warn {
    padding: 20px;
    background-color: red;
    color: #fff;

    p,
    ul {
      font-size: 14px;
    }

    h4 {
      color: #fff;
    }
  }
}
