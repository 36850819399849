.xa-messenger-wrapper {
  width: 100%;
  height: 100%;
  max-width: 1280px;
  margin: 0 auto;

  .messenger-container {
    display: flex;

    .left-side {
      min-width: 360px;
      .title {
        display: flex;
        align-items: center;
        height: 80px;
        font-size: 20px;
        color: #222222;
      }
    }
    .right-side {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 30px;

      .settings {
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .menu {
    height: 80px;
    font-size: 20px;
    color: #222222;
    display: flex;
    justify-content: space-between;

    .title {
      display: flex;
      align-items: center;
      cursor: pointer;
      width: 360px;
    }
  }
}

.conversations-wrapper {
  float: left;
  height: calc(100% - 86px);
}

.conversations-wrapper {
  width: 400px;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
}

.chats-wrapper {
  //width: calc(100% - 400px);
}
