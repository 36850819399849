.about-wrapper {
  font-family: "Poppins", sans-serif;

  .menu {
    background-color: #ffd07d;
    text-align: center;
    font-size: 20pt;
    padding: 16px;

    a,
    span {
      width: 370px;
      display: inline-block;
    }

    a,
    a:visited,
    a:hover {
      color: #fff;
    }
  }

  .content1 {
    background-color: #7cc8a4;
    text-align: center;
    height: 500px;

    .title {
      padding-top: 32px;
      padding-bottom: 48px;
      font-size: 24pt;
      font-weight: bold;
    }

    .block {
      vertical-align: top;
      display: inline-block;
      width: 370px;

      .text {
        padding-top: 16px;
        margin-top: 16px;
        font-weight: normal;
        font-size: 14pt;
        line-height: normal;
        text-align: center;
        color: #fff;
      }

      a {
        color: #fff;
      }

      a:visited {
        color: #fff;
      }
    }
  }

  .content2 {
    text-align: center;

    .title {
      padding-top: 32px;
      padding-bottom: 16px;
      font-size: 24pt;
      font-weight: bold;
    }

    p {
      font-size: 16pt;
      line-height: 24pt;
    }

    .block {
      margin-top: 16px;
      vertical-align: top;
      display: inline-block;
      width: 370px;
      padding-top: 24px;
      padding-bottom: 24px;
      text-align: center;
      font-size: 18pt;
      background-color: #69c09e;
      color: #fff;
    }
  }

  .content3 {
    margin-top: 52px;
    text-align: left;
    background-color: #efefef;
    height: auto;
    font-size: 25pt;
    line-height: 32pt;

    .left {
      width: 70%;
      padding-left: 100px;
      padding-right: 50px;

      .title {
        padding-top: 32px;
        font-size: 30pt;
        font-weight: bold;
      }

      .box {
        margin-top: 24px;
        background-color: #fff;
        padding: 16px;
        width: 100%;

        textarea {
          font-size: 25pt;
          resize: none;
          padding: 0;
          border: 0;
          border-style: none;
          border-color: Transparent;
          overflow: auto;
        }
      }

      button {
        margin-top: 16px;
        background-color: #69c09e;
        font-size: 25pt;
        font-weight: bold;
        width: 180px;
        height: 64px;
      }
    }

    .right {
      width: 30%;
      padding: 32px;
      text-align: left;
      margin-top: 100px;
      margin-bottom: 52px;
      border-left: 1px solid #000;
    }
  }
}
