.blog-post-wrapper {
  width: 100%;
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  margin-top: 58px;

  table {
    width: 100%;

    td {
      vertical-align: top;
      padding: 0;
      margin: 0;
    }
  }

  .right {
    float: right;
    width: 180px;
    padding-right: 72px;
  }

  .left {
    float: left;
    width: 295px;
    padding-left: 58px;

    .title {
      font-size: 30px;
      font-weight: bold;
      line-height: normal;
    }

    .author {
      margin-top: 16px;
      font-size: 20px;
      font-style: italic;
      line-height: normal;
    }
  }

  .middle {
    margin: 0px 180px 0px 315px;
  }
}
